import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import styled from "@emotion/styled";
import { alpha } from "@mui/system";
import { grey } from "@mui/material/colors";
import { debounce } from "lodash";

const Search = styled("div")(({ theme }) => ({
  position: "relative",
  borderRadius: theme.shape.borderRadius,
  border: "1px solid",
  borderColor: alpha(theme.palette.common.black, 0.15),
  "&:hover": {
    borderColor: alpha(theme.palette.common.black, 0.25),
  },
  marginLeft: 0,
  width: "100%",
  [theme.breakpoints.up("sm")]: {
    marginLeft: theme.spacing(1),
    width: "auto",
  },
}));

const SearchIconWrapper = styled("div")(({ theme }) => ({
  padding: theme.spacing(0, 1),
  height: "100%",
  position: "absolute",
  pointerEvents: "none",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: grey[700],
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: grey[800],
  "& .MuiInputBase-input": {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create("width"),
    width: "100%",
    [theme.breakpoints.up("sm")]: {
      width: "12ch",
      "&:focus": {
        width: "20ch",
      },
    },
  },
}));

function SearchInput({ setTerm }) {
  const handleChange = (e) => {
    let value = e.target.value;
    if (value.length < 3) value = null;
    setTerm(value);
  };

  const debouncedHandleChange = debounce(handleChange, 500);

  return (
    <Search>
      <SearchIconWrapper>
        <SearchIcon />
      </SearchIconWrapper>
      <StyledInputBase
        placeholder="Search…"
        inputProps={{ "aria-label": "search" }}
        onChange={debouncedHandleChange}
      />
    </Search>
  );
}

export default SearchInput;
