import { memo, useState } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { Alert, Button, TextField } from "@mui/material";
import { Box } from "@mui/system";
import { useManufacturerApps } from "./ManufacturerAppProvider";
import { useNavigate } from "react-router-dom";

const schema = Yup.object().shape({
  name: Yup.string().required("Required"),
  mobile_bundle_id: Yup.string().required("Required"),
  // stitchain_user: Yup.string().required("Required"),
});

function ManufacturerAppsForm({ userId = null, handleSuccess }) {
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { create } = useManufacturerApps();

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      const resData = await create(values);
      reset();
      handleSuccess(resData.id);
    } catch (e) {
      if (e.errors)
        setError(
          <>
            <div>Server error.</div>
            {e.errors?.data?.map((e) => (
              <p>{e}</p>
            ))}
          </>
        );
      console.log("e", e);
    }
  };

  return (
    <Box
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      noValidate
      sx={{ mt: 1, maxWidth: 400 }}
    >
      {error && (
        <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
          {error}
        </Alert>
      )}

      <TextField
        margin="normal"
        required
        fullWidth
        label="Name"
        name="name"
        type="name"
        autoFocus
        {...register("name")}
        error={!!errors.name}
        helperText={errors.name?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <TextField
        margin="normal"
        required
        fullWidth
        label="Mobile Bundle Id"
        name="mobile_bundle_id"
        {...register("mobile_bundle_id")}
        error={!!errors.mobile_bundle_id}
        helperText={errors.mobile_bundle_id?.message}
        InputLabelProps={{
          shrink: true,
        }}
      />

      {/* <TextField
        margin="normal"
        required
        fullWidth
        label="Stitchain User"
        name="stitchain_user"
        {...register("stitchain_user")}
        error={!!errors.stitchain_user}
        helperText={errors.stitchain_user?.message}
        InputLabelProps={{
          shrink: true,
        }}
      /> */}

      <LoadingButton
        type="submit"
        variant="contained"
        sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
        loading={isSubmitting}
      >
        Create
      </LoadingButton>
      <Button
        variant="text"
        sx={{ mt: 3, mb: 2, float: "right" }}
        onClick={() => navigate(-1)}
      >
        Cancel
      </Button>
    </Box>
  );
}

export default memo(ManufacturerAppsForm);
