import {
  Button,
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Box } from "@mui/system";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { memo } from "react";
import { grey } from "@mui/material/colors";
import Dropdown from "./Dropdown";

function TableColsContainer({
  empty,
  status,
  cols,
  tableProps = {},
  children,
  ...rest
}) {
  return (
    <Box sx={{ position: "relative" }}>
      {status === "fetching" && (
        <Box
          sx={{
            position: "absolute",
            top: "20px",
            left: "calc(50% - 35px)",
            p: 2,
            width: "70px",
            backgroundColor: grey[100],
            zIndex: 9999,
            borderRadius: "5px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            boxShadow: "0px 0px 1px 1px rgba(0,0,0,0.08)",
          }}
        >
          <CircularProgress size={21} />
        </Box>
      )}
      <TableContainer variant="outlined" component={Paper} {...rest}>
        <Table {...{ sx: { minWidth: 500 }, size: "small", ...tableProps }}>
          <TableHead>
            <TableRow>
              {cols.map((col) => (
                <TableCell key={col.label} {...col.options}>
                  {col.filter ? (
                    <Dropdown content={col.filter} space={5}>
                      <Button
                        variant={col.filtered ? 'contained' : 'text'}
                        disableElevation
                        endIcon={<ArrowDropDownIcon />}
                      >
                        {col.label}
                      </Button>
                    </Dropdown>
                  ) : (
                    col.label
                  )}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!empty ? (
              children
            ) : (
              <TableRow>
                <TableCell align="center" colSpan={cols.length}>
                  No data available
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

export default memo(TableColsContainer);
