import { ListItem, ListItemButton, ListItemText } from "@mui/material";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import styled from "@emotion/styled";

const StyledListItem = styled(ListItem)`
  &:not(:last-child) {
    border-bottom: #eee 1px solid;
  }
  span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .col {
      display: inline;
      &.label {
        width: 150px;
        text-transform: uppercase;
        font-size: 12px;
      }
      &.value {
        flex-grow: ${(props) => (props.linked ? 1 : 0)};
      }
      &.arrow {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
`;

const CustomListItem = ({ label, value, handleClick = null, ...props }) => {
  let itemProps = { ...props };
  if(handleClick) itemProps.linked = '1'
  if (handleClick) {
    itemProps.onClick = handleClick;
    itemProps.as = ListItemButton;
  }

  return (
    <StyledListItem {...itemProps}>
      <ListItemText>
        {label && <div className="col label">{label}</div>}
        <div className="col value">
          {value.map((v, i) => (
            <span key={i}>{v}</span>
          ))}
        </div>
        {Boolean(handleClick) && (
          <div className="col arrow">
            <KeyboardArrowRightIcon color="col action" />
          </div>
        )}
      </ListItemText>
    </StyledListItem>
  );
};

export default CustomListItem;
