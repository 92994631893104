import {
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from "@mui/material";
import FolderZipIcon from "@mui/icons-material/FolderZip";
import { formatDistance, parseISO, subDays } from "date-fns";
import { Box } from "@mui/system";
import { useRequest } from "../../services/request";

function ReleasesRow({ row }) {
  const req = useRequest();

  const handleClick = () => {
    (async () => {
      const resData = await req(`releases/${row.id}/`, null, {}, true);
      const link = document.createElement("a");
      link.href = resData.assets_package_file;
      link.target = "_blank";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    })();
  };

  return (
    <Card variant="outlined" sx={{ mb: 3 }}>
      <CardContent>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h4" component="div" gutterBottom>
            {row.tag}
          </Typography>
          <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom>
            {formatDistance(subDays(parseISO(row.created_on), 3), new Date(), {
              addSuffix: true,
            })}
          </Typography>
        </Box>
        <Typography variant="body2" gutterBottom>
          {row.description}
        </Typography>
      </CardContent>
      <CardActions
        sx={{ borderTop: "#ccc 1px solid", borderColor: "grey.300", p: 2 }}
      >
        <Button
          variant="outlined"
          size="small"
          startIcon={<FolderZipIcon />}
          onClick={handleClick}
        >
          Download
        </Button>
      </CardActions>
    </Card>
  );
}

export default ReleasesRow;
