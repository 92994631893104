import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { useAuth } from "../../services/auth";
import { LoadingButton } from "@mui/lab";
import ErrorIcon from "@mui/icons-material/Error";
import { orange } from "@mui/material/colors";

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function Email() {
  // const [error, setError] = useState(null);

  const navigate = useNavigate();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
        email: auth.user.user.email
    }
  });

  const onSubmit = async (values) => {
    try {
      await auth.editAccount(values);
      navigate("/profile");
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <div id="edit-name">
      <Typography variant="h4">
        <Link to={-1}>
          <ArrowBackIcon color="action" />
        </Link>{" "}
        Email
      </Typography>
      <Typography
        variant="subtitle1"
        sx={{ display: "flex", alignItems: "center" }}
      >
        <ErrorIcon sx={{ color: orange[500], mr: 1 }} fontSize="small" />
        This step require verification
      </Typography>

      <Card variant="outlined" sx={{ maxWidth: 400, mt: 4 }}>
        <CardHeader subheader="Change Email" />

        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {/* {error && (
              <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
                {error}
              </Alert>
            )} */}

            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="email"
              type="email"
              label="email"
              name="email"
              {...register("email")}
              error={!!errors.email}
              helperText={errors.email?.message}
            />

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
            <Button
              variant="text"
              sx={{ mt: 3, mb: 2, float: "right" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Email;
