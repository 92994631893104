import { memo } from "react";
import {
  CircularProgress,
  IconButton,
  TableCell,
  TableRow,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { red } from "@mui/material/colors";
import { Access } from "../../services/auth";

const rowStyles = {
  "&:last-child td, &:last-child th": { border: 0 },
};

function ManufacturerAppsRow({ row = null, status, handleDelete }) {
  if (!row) return "no data";
  return (
    <TableRow sx={rowStyles}>
      <TableCell>{row.id}</TableCell>
      <TableCell>{row.name}</TableCell>
      <TableCell>{row.mobile_bundle_id}</TableCell>
      <TableCell>{row.api_key}</TableCell>

      <TableCell sx={{ width: 140 }} align="right">
        <Access path="/manufacturer-apps/delete">
          <IconButton
            sx={{ color: red[400] }}
            aria-label="delete manufacturer app"
            onClick={() => {
              if (window.confirm("Are you sure?")) handleDelete(row.id);
            }}
          >
            {status.split(" ")[0] === "deleting" &&
            Number(status.split(" ")[1]) === row.id ? (
              <CircularProgress size={21} />
            ) : (
              <DeleteIcon fontSize="small" />
            )}
          </IconButton>
        </Access>
      </TableCell>
    </TableRow>
  );
}

export default memo(ManufacturerAppsRow);
