import { Alert, Snackbar } from "@mui/material";
import { createContext, useReducer, useContext } from "react";

const UtilsContext = createContext(null);

export function useUtils() {
  return useContext(UtilsContext);
}

const initState = {
  alert: {
    open: false,
    content: "",
    autoHideDuration: 5000,
    severity: "success",
    position: { vertical: "bottom", horizontal: "left" },
  },
  // loading: false,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "set_alert":
      return { ...state, alert: { ...state.alert, ...action.payload } };
    // case "loading":
    //   return { ...state, loading: action.loading };
    default:
      throw new Error(`Invalid dispatch type: ${action.type}`);
  }
};

export default function UtilsProvider({ children }) {
  let [state, dispatch] = useReducer(reducer, initState);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    dispatch({ type: "set_alert", payload: { open: false } });
  };

  const notify = (content) => {
    dispatch({ type: "set_alert", payload: { open: true, content } });
  };

  let value = { state, notify, dispatch };

  return (
    <UtilsContext.Provider value={value}>
      <Snackbar
        open={state.alert.open}
        autoHideDuration={state.alert.autoHideDuration}
        onClose={handleClose}
        anchorOrigin={state.alert.position}
      >
        <Alert
          onClose={handleClose}
          severity={state.alert.severity}
          sx={{ width: "100%" }}
        >
          {state.alert.content}
        </Alert>
      </Snackbar>

      {children}
    </UtilsContext.Provider>
  );
}
