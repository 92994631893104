import { memo } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ManufacturerAppsForm from "./ManufacturerAppsForm";
import { useUtils } from "../../services/utilsProvider";
import { Box } from "@mui/system";

function ManufacturerAppsAdd() {
  const navigate = useNavigate();
  const { notify } = useUtils();

  const handleSuccess = (id) => {
    navigate(`/manufacturer-apps`);
    notify(
      "App created successfully."
    );
  };

  return (
    <Box sx={{ maxWidth: 500, p: 2}} id="add-user">
      <Typography variant="h4">
        <Link to={-1}>
          <ArrowBackIcon color="action" />
        </Link>{" "}
        Create a new App
      </Typography>
      {/* <Typography variant="body1" marginTop={1}>
        The new user will recieve, by email, the intruction on how to set a
        password for his newly created account.
      </Typography> */}
      <Box  sx={{ mt: 4, p: 0 }}>
          <ManufacturerAppsForm handleSuccess={handleSuccess} />
      </Box>
    </Box>
  );
}

export default memo(ManufacturerAppsAdd);
