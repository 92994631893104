import { useNavigate } from "react-router-dom";
import {
  Card,
  CardContent,
  CardHeader,
  List,
  Typography,
} from "@mui/material";
import { useAuth } from "../../services/auth";
import CustomListItem from "../../ui/CustomListItem";

function ProfileInfo() {
  const auth = useAuth();
  const navigate = useNavigate();

  if (!auth.user) return;

  return (
    <div id="profile-info">
      <Typography variant="h4">
        Your profile info on Stitchain services
      </Typography>
      <Card variant="outlined" sx={{ maxWidth: 500, mt: 4 }}>
        <CardHeader
          title="Personal info"
          subheader="Personal info and options to manage it."
        />
        <CardContent>
          <List>
            <CustomListItem
              label="Username"
              value={[auth.user.user.username]}
              handleClick={() => navigate("username")}
            />
            <CustomListItem
              label="Email"
              value={[auth.user.user.email]}
              handleClick={() => navigate("email")}
            />
            <CustomListItem
              label="Name"
              value={[`${auth.user.user.first_name} ${auth.user.user.last_name}`]}
              handleClick={() => navigate("name")}
            />
          </List>
        </CardContent>
      </Card>

      <Card variant="outlined" sx={{ maxWidth: 500, mt: 4 }}>
        <CardHeader
          title="Password"
          subheader="A secure password helps protect your Stitchain Account"
        />
        <CardContent>
          <List>
            <CustomListItem
              value={["••••••••"]}
              handleClick={() => navigate("password")}
            />
          </List>
        </CardContent>
      </Card>
    </div>
  );
}

export default ProfileInfo;
