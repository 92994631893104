import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useAuth } from "../../services/auth";
import { Alert, Container, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";

const schema = Yup.object().shape({
  new_password1: Yup.string().min(8, "Too Short!").required("Required"),
  new_password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
});

const displayErrors = (errors) =>
  errors?.map((error, i) => <span key={i}>{error}</span>);

function SetPass() {
  const [msg, setMsg] = useState("");
  const [countDown, setCountDown] = useState(6);
  const [serverErrors, setServerErrors] = useState(null);

  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { setPass } = useAuth();

  const uid = searchParams.get("uid");
  const token = searchParams.get("token");

  let countDownInterval;
  let redirectTimeout;

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    return () => {
      clearInterval(countDownInterval);
      clearTimeout(redirectTimeout);
    };
  });

  const onSubmit = async (values) => {
    try {
      const data = await setPass({ ...values, uid, token });
      setMsg(data.detail);
      setServerErrors(null);
      redirectTimeout = setTimeout(() => navigate("/login"), 6000);
      countDownInterval = setInterval(() => setCountDown((c) => --c), 1000);
    } catch (e) {
      console.log("error", e);
      setServerErrors(e.errors);
      setMsg("");
    }
  };

  if (!uid || !token) {
    navigate("/")
    return;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5">
          Create a password for your new account
        </Typography>

        <Box
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          sx={{ mt: 1 }}
        >
          {serverErrors?.token && (
            <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
              The token provided is invalid, please contact administrator.
            </Alert>
          )}

          {msg.length ? (
            <Alert severity="success" sx={{ mt: 3, mb: 2 }}>
              {msg}
              <Box sx={{ mt: 1, fontSize: "13px" }}>
                You will be redirected to login page in {countDown} seconds
              </Box>
            </Alert>
          ) : (
            ""
          )}

          <TextField
            autoFocus
            margin="normal"
            fullWidth
            id="new_password1"
            type="password"
            label="Password"
            name="new_password1"
            {...register("new_password1")}
            error={Boolean(errors.new_password1 || serverErrors?.new_password1)}
            helperText={
              errors.new_password1?.message ||
              displayErrors(serverErrors?.new_password1)
            }
          />

          <TextField
            margin="normal"
            fullWidth
            id="new_password2"
            type="password"
            label="Confirm Password"
            name="new_password2"
            {...register("new_password2")}
            error={Boolean(errors.new_password2 || serverErrors?.new_password2)}
            helperText={
              errors.new_password2?.message ||
              displayErrors(serverErrors?.new_password2)
            }
          />

          <LoadingButton
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            loading={isSubmitting}
          >
            Set Password
          </LoadingButton>
        </Box>
      </Box>
    </Container>
  );
}

export default SetPass;
