import { createTheme, ThemeProvider } from "@mui/material/styles";
import AuthProvider from "./auth";
import UtilsProvider from "./utilsProvider";
import ManufacturerAppProvider from "../views/manufacturerApps/ManufacturerAppProvider";

const theme = createTheme();

export default function Providers({ children }) {
  return (
    <UtilsProvider>
      <AuthProvider>
        <ManufacturerAppProvider>
          <ThemeProvider theme={theme}>{children}</ThemeProvider>
        </ManufacturerAppProvider>
      </AuthProvider>
    </UtilsProvider>
  );
}
