import { Route } from "react-router-dom";

import { RequireAuth } from "../../services/auth";

import ManufacturerApps from "./ManufacturerApps";
// import Product from "./Product";
import List from "./ManufacturerAppsList";
import ManufacturerAppsAdd from "./ManufacturerAppsAdd";
// import EditProduct from "./EditProduct";
// import AddItem from "../items/AddItem";
// import EditItem from "../items/EditItem";

export default (
  <Route
    path="manufacturer-apps"
    element={
      <RequireAuth>
        <ManufacturerApps />
      </RequireAuth>
    }
  >
    <Route
      index
      element={
        <RequireAuth>
          <List />
        </RequireAuth>
      }
    />
    {/* <Route
      path=":id"
      element={
        <RequireAuth>
          <Product />
        </RequireAuth>
      }
    /> */}
    <Route
      path="new"
      element={
        <RequireAuth>
          <ManufacturerAppsAdd />
        </RequireAuth>
      }
    />
    {/* <Route
      path="edit/:id"
      element={
        <RequireAuth>
          <EditProduct />
        </RequireAuth>
      }
    /> */}
    {/* <Route
      path=":pid/items/new"
      element={
        <RequireAuth>
          <AddItem />
        </RequireAuth>
      }
    /> */}
    {/* <Route
      path=":pid/items/:id/edit/"
      element={
        <RequireAuth>
          <EditItem />
        </RequireAuth>
      }
    /> */}
  </Route>
);
