import {
  createContext,
  useState,
  useContext,
  useEffect,
  useCallback,
  cloneElement,
} from "react";
import { useLocation, Navigate, Link, useNavigate } from "react-router-dom";
import { useRequest } from "./request";
import { access } from "../config";

const AuthContext = createContext(null);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }) {
  let [user, setUser] = useState(JSON.parse(localStorage.getItem("user")));
  const req = useRequest();

  useEffect(() => {
    if (user) return;
    const localUser = localStorage.getItem("user");
    if (localUser) setUser(JSON.parse(localStorage.getItem("user")));
  }, [user]);

  function changeCurrentUser(user) {
    user.user.permissions = user.user.roles?.reduce(
      (a, b) => [...a, ...Object.keys(b.permissions)],
      []
    );
    delete user.user.roles;
    window.localStorage.setItem("user", JSON.stringify(user));
    setUser(user);
  }

  let signin = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(`rest-auth/login/`, data);
          window.localStorage.setItem(
            "user",
            JSON.stringify({ user: res.user, tokens: res })
          );
          setUser({ user: res.user, tokens: res, config: res.config });
          changeCurrentUser({ user: res.user, tokens: res });
          resolve();
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let editAccount = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `rest-auth/user/`,
            data,
            { method: "PATCH" },
            true
          );
          changeCurrentUser(resData);
          resolve(resData);
        } catch (error) {
          reject(error);
          console.log(error);
        }
      });
    },
    [req]
  );

  let changePass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `rest-auth/password/change/`,
            data,
            { method: "POST" },
            true
          );
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let forgotPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const resData = await req(
            `rest-auth/password/reset/`,
            data,
            { method: "POST" },
            true
          );
          resolve(resData);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let setPass = useCallback(
    (data) => {
      return new Promise(async (resolve, reject) => {
        try {
          const res = await req(
            `rest-auth/password/reset/confirm/`,
            data,
            { method: "POST" },
            false
          );
          resolve(res);
        } catch (error) {
          reject(error);
        }
      });
    },
    [req]
  );

  let signout = useCallback(() => {
    return new Promise((resolve) => {
      window.localStorage.removeItem("Token");
      window.localStorage.removeItem("user");
      setUser(null);
      resolve();
    });
  }, []);

  const saveTokens = useCallback(
    (newTokens) => {
      const userTokens = { ...user, tokens: {...user.tokens, ...newTokens} };
      window.localStorage.setItem("user", JSON.stringify(userTokens));
      setUser(userTokens);
    },
    [user]
  );

  let value = {
    user,
    signin,
    changePass,
    forgotPass,
    signout,
    editAccount,
    setPass,
    saveTokens
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function RequireAuth({ children }) {
  let location = useLocation();

  const user = localStorage.getItem("user");
  const { pathname } = location;

  if (!user) return <Navigate to="/login" state={{ from: location }} replace />;

  if (checkAccess(pathname)) return children;

  return <Navigate to="/no-access" replace />;
}

export function Access({ path, children, type, ...props }) {
  const navigate = useNavigate();
  const hasAccess = checkAccess(path);

  if (!hasAccess) return null;

  switch (type) {
    case "click":
      return cloneElement(children, {
        onClick: () => navigate(path),
        ...props,
      });
    case "link":
      return (
        <Link to={path} {...props}>
          {children}
        </Link>
      );
    default:
      return children;
  }
}

function checkAccess(path) {
  const user = JSON.parse(localStorage.getItem("user"));
  const key = getRequiredKey(path);

  if (!key || user.user?.permissions.includes(key)) return true;

  return false;
}

function getRequiredKey(path) {
  for (const key in access)
    for (let i = 0; i < access[key].length; i++)
      if (path.match(access[key][i])) return key;

  return null;
}
