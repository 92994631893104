import { getPoint, position } from "../helpers/relativePosition";
import React, { useState, useRef, useEffect } from "react";
import styled from "styled-components";
import Portal from "./Portal";

const StyledDropdown = styled.span.attrs((p) => ({
  delay: p.delay || 0.05,
}))`
  position: fixed;
  top: ${(p) => p.posRef.current.y}px;
  left: ${(p) => p.posRef.current.x}px;
  pointer-events: ${(p) => p.show ? 'all' : 'none'};
  z-index: 99999;
  display: inline-block;
  white-space: nowrap;
  opacity: ${(p) => p.show};

  transition-property: transform, opacity !important;
  transition-duration: 0.06s !important;
  transition-timing-function: cubic-bezier(0, 0, 0.2, 1) !important;
  transition-delay: ${(p) => (p.show ? p.delay : 0.02)}s !important;

  transform-origin: ${(p) => position(p.placment).negate()};
  transform: scale(${(p) => (p.show ? 1 : 0.7)});
`;

function Dropdown({
  content,
  placement = "bottom",
  space = 15,
  children,
  disabled = 0,
  delay,
  click = true,
}) {
  const [show, setShow] = useState(0);
  const posRef = useRef({ x: 0, y: 0 });
  const dropdownRef = useRef();
  const trigerRef = useRef();

  const handleMOver = (e) => {
    setShow(1);
    posRef.current = getPoint(
      e.currentTarget,
      dropdownRef.current,
      placement,
      space
    );
  };
  const handleMOut = () => setShow(0);

  let handleOutsideClick = (e) => {
    var isClickInsideElement =
      dropdownRef.current.contains(e.target) ||
      trigerRef.current.contains(e.target);
    if (!isClickInsideElement) {
      //Do something click is outside specified element
      setShow(0);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleOutsideClick);
    document.addEventListener("scroll", () => setShow(0));
    window.addEventListener("resize", () => setShow(0));
    return () => {
      document.removeEventListener("click", handleOutsideClick);
      document.removeEventListener("scroll", () => setShow(0));
      window.addEventListener("resize", () => setShow(0));
    };
  }, []);

  const handleClick = (e) => {
    // e.stopPropagation();
    if (show === 0) {
      setShow(1);
      posRef.current = getPoint(
        e.currentTarget, // or trigerRef.current
        dropdownRef.current,
        placement,
        space
      );
    } else {
      setShow(0);
    }
  };

  const cloneProps = {};
  if (click) {
    cloneProps.onClick = handleClick;
    cloneProps.ref = trigerRef;
  } else {
    cloneProps.onMouseOver = handleMOver;
    cloneProps.onMouseOut = handleMOut;
  }

  return (
    <>
      {disabled ? children : React.cloneElement(children, cloneProps)}
      {disabled || (
        <Portal>
          <StyledDropdown
            delay={delay}
            ref={dropdownRef}
            posRef={posRef}
            show={show}
          >
            {content}
          </StyledDropdown>
        </Portal>
      )}
    </>
  );
}

export default Dropdown;
