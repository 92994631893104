import { memo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  AppBar as MuiAppBar,
  IconButton,
  Menu,
  MenuItem,
  Toolbar,
  Typography,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { styled } from "@mui/material/styles";
import { AccountCircle } from "@mui/icons-material";
import { grey } from "@mui/material/colors";

import { drawerWidth } from "./Layout";
import { useAuth } from "../services/auth";
import logo from "../assets/logo-white.png";
import { Box } from "@mui/system";

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function Header({ handleDrawerOpen, open }) {
  const auth = useAuth();
  const navigate = useNavigate();

  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    await auth.signout();
    handleClose();
    navigate("/");
  };

  return (
    <AppBar position="fixed" open={open} sx={{backgroundColor: grey[800]}}>
      <Toolbar>
        {auth.user && (
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
        )}
        <Box sx={{ pb: 1, pt: 2, pr: 3 }}>
          <img src={logo} width="35" alt="Stitchain logo" />
        </Box>
        <Typography variant="h6" noWrap component="div" sx={{ flexGrow: 1 }}>
          <span>Stitchain Dev Center</span>
        </Typography>
        {auth.user && (
          <div>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleMenu}
              color="inherit"
            >
              <AccountCircle />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              <MenuItem
                onClick={() => {
                  navigate("profile");
                  handleClose();
                }}
              >
                Profile
              </MenuItem>
              <MenuItem onClick={handleLogout}>Logout</MenuItem>
            </Menu>
          </div>
        ) }
        {/*  : (
           <Button component={Link} to="/login" color="inherit">
             Login
           </Button>
         ) */}

        {/* <Button component={Link} to="/register" color="inherit">
              Register
            </Button> */}
        {/* </>
         )} */}
      </Toolbar>
    </AppBar>
  );
}

export default memo(Header);
