import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { useAuth } from "../../services/auth";
import { LoadingButton } from "@mui/lab";
import {useUtils} from '../../services/utilsProvider';

const schema = Yup.object().shape({
  old_password: Yup.string().min(8, "Too Short!").required("Required"),
  new_password1: Yup.string().min(8, "Too Short!").required("Required"),
  new_password2: Yup.string()
    .min(8, "Too Short!")
    .required("Required")
    .oneOf([Yup.ref("new_password1"), null], "Passwords must match"),
});

const displayErrors = (errors) =>
  errors?.map((error, i) => <span key={i}>{error}</span>);

function Password() {
  const [serverErrors, setServerErrors] = useState(null);

  const navigate = useNavigate();

  const {notify} = useUtils();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = async (values) => {
    try {
      await auth.changePass(values);
      notify("New password has been saved");
      navigate("/profile");
    } catch (e) {
      console.log("error", e);
      setServerErrors(e.errors);
    }
  };

  return (
    <div id="edit-name">
      <Typography variant="h4">
        <Link to={-1}>
          <ArrowBackIcon color="action" />
        </Link>{" "}
        Password
      </Typography>
      <Typography variant="subtitle1">
        Increase account security by changing password regularly
      </Typography>

      <Card variant="outlined" sx={{ maxWidth: 400, mt: 4 }}>
        <CardHeader subheader="Change Password" />

        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {/* {serverErrors && (
              <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
                {serverErrors}
              </Alert>
            )} */}

            <TextField
              autoFocus
              role="password"
              margin="normal"
              fullWidth
              id="old_password"
              type="password"
              label="Old Password"
              name="old_password"
              {...register("old_password")}
              error={Boolean(errors.old_password || serverErrors?.old_password)}
              helperText={
                errors.old_password?.message ||
                displayErrors(serverErrors?.old_password)
              }
            />

            <TextField
              role="password"
              margin="normal"
              fullWidth
              id="new_password1"
              type="password"
              label="Password"
              name="new_password1"
              {...register("new_password1")}
              error={Boolean(errors.new_password1 || serverErrors?.new_password1)}
              helperText={
                errors.new_password1?.message ||
                displayErrors(serverErrors?.new_password1)
              }
            />

            <TextField
              margin="normal"
              role="password"
              fullWidth
              id="new_password2"
              type="password"
              label="Confirm Password"
              name="new_password2"
              {...register("new_password2")}
              error={Boolean(errors.new_password2 || serverErrors?.new_password2)}
              helperText={
                errors.new_password2?.message ||
                displayErrors(serverErrors?.new_password2)
              }
            />

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
            <Button
              variant="text"
              sx={{ mt: 3, mb: 2, float: "right" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Password;
