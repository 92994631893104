import { Card, CardContent, CardHeader } from "@mui/material";

const Dashbaord =  () => (
  <Card>
    <CardHeader title="Welcome to the Dev Center" />
    <CardContent></CardContent>
  </Card>
);

export default Dashbaord;
