import { memo, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  TablePagination,
  Toolbar,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import TableColsContainer from "../../ui/TableColsContainer";
import ManufacturerAppsRow from "./ManufacturerAppsRow";
import { useManufacturerApps } from "./ManufacturerAppProvider";
import { Box } from "@mui/system";
import { Access } from "../../services/auth";

const cols = [
  { label: "ID" },
  { label: "Name" },
  { label: "Mobile Bundle ID" },
  { label: "API Key" },
  { label: "Actions", options: { align: "right" } },
];

function ManufacturerAppsList() {

  const {
    state: { list, status, count, page, perPage },
    dispatch,
    fetchList,
    remove,
  } = useManufacturerApps();

  useEffect(() => {
    fetchList(page, perPage);
  }, [fetchList, page, perPage]);

  const handleDelete = (id) => {
    remove(id);
  };

  const handleChangePage = (event, newPage) =>
    dispatch({ type: "set_page", payload: newPage });

  const handleChangeRowsPerPage = (event) => {
    dispatch({
      type: "set_per_page",
      payload: parseInt(event.target.value, 10),
    });
    dispatch({ type: "set_page", payload: 0 });
  };

  return (
    <Card id="manufacturer-apps-list">
      <CardHeader title="My Apps List" component="h2" sx={{ mt: 0, mb: 0 }} />

      <CardContent>
        <Access path="/manufacturer-apps/new">
          <Toolbar sx={{ pl: { sm: 0 }, pr: { xs: 0, sm: 0 } }}>
              <Box sx={{ flex: "1 1 100%" }}>
                <Button
                  component={Link}
                  to="new"
                  variant="text"
                  startIcon={<AddIcon />}
                >
                  New App
                </Button>
              </Box>
          </Toolbar>
        </Access>

        <TableColsContainer
          empty={list?.length === 0}
          status={status}
          aria-label="manufacturer apps table"
          cols={cols}
        >
          {list.map((row) => (
            <ManufacturerAppsRow
              key={row.id}
              {...{ row, status, handleDelete }}
            />
          ))}
        </TableColsContainer>

        <TablePagination
          component="div"
          data-testid="manufacturer-apps-pagination"
          count={count}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={perPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </CardContent>
    </Card>
  );
}

export default memo(ManufacturerAppsList);
