import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Button, Card, CardContent, CardHeader, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { useAuth } from "../../services/auth";
import { LoadingButton } from "@mui/lab";

function Name() {
    // const [error, setError] = useState(null);

  const navigate = useNavigate();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: {
        first_name: auth.user.user.first_name,
        last_name: auth.user.user.last_name,
    }
  });

  const onSubmit = async (values) => {
    try {
      await auth.editAccount(values);

      navigate("/profile");
    } catch (error) {
      console.log("error", error);
    //   if (error.status === 400) setError("Wrong email/password combination!");
    }
  };

  return (
    <div id="edit-name">
      <Typography variant="h4">
        <Link to={-1}>
          <ArrowBackIcon color="action" />
        </Link>{" "}
        Name
      </Typography>
      <Typography variant="subtitle1">
      Changes to your name will be reflected across your Stitshain Account.
      </Typography>

      <Card variant="outlined" sx={{ maxWidth: 400, mt: 4 }}>
        <CardHeader subheader="Change Name" />

        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            
            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="first_name"
              label="First Name"
              name="first_name"
              {...register("first_name")}
            />

            <TextField
              margin="normal"
              fullWidth
              id="last_name"
              label="Last Name"
              name="last_name"
              autoComplete="last_name"
              {...register("last_name")}
            />

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
            <Button
              variant="text"
              sx={{ mt: 3, mb: 2, float: "right" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Name;
