var apiUrl;

if (process.env.REACT_APP_ENV === "production")
  apiUrl = "https://api.stitchain.com/v1";
else if (process.env.REACT_APP_ENV === "sandbox")
  apiUrl = "https://sandbox.api.stitchain.com/v1";
else {
  apiUrl = "https://staging.api.stitchain.com/v1";
}

const access = {
  1007: [/\/manufacturer-apps$/i],
  1008: [/\/manufacturer-apps\/new/i, "/manufacturer-apps/delete"],
};

export { apiUrl, access };
