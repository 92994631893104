import { Avatar, Container, Typography } from "@mui/material";
import { Box } from "@mui/system";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";

function NoAccess() {
  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Avatar sx={{ m: 1, mb: 2, bgcolor: "secondary.main" }}>
          <LockOutlinedIcon />
        </Avatar>

        <Typography variant="h5" component="h1" gutterBottom>
          Access Denied
        </Typography>

        <Typography variant="body" textAlign="center">
          Your account dosn't have permission to access these contents.
        </Typography>
      </Box>
    </Container>
  );
}

export default NoAccess;
