import { Link, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  Alert,
  Button,
  Card,
  CardContent,
  CardHeader,
  TextField,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box } from "@mui/system";
import { useAuth } from "../../services/auth";
import { LoadingButton } from "@mui/lab";
import { useState } from "react";

function Username() {
  const [serverError, setServerError] = useState(null);

  const navigate = useNavigate();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { isSubmitting },
  } = useForm({
    defaultValues: { username: auth.user.user.username },
  });

  const onSubmit = async (values) => {
    try {
      await auth.editAccount(values);
      navigate("/profile");
    } catch (error) {
      console.log("error", error);
      setServerError(error?.errors?.username);
    }
  };

  return (
    <div id="edit-username">
      <Typography variant="h4">
        <Link to={-1}>
          <ArrowBackIcon color="action" />
        </Link>{" "}
        Username
      </Typography>
      <Typography variant="subtitle1">
        Changes to your username will be reflected across your Stitshain
        Account.
      </Typography>

      <Card variant="outlined" sx={{ maxWidth: 400, mt: 4 }}>
        <CardHeader subheader="Change Username" />

        <CardContent>
          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1 }}
          >
            {serverError && (
              <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
                {serverError}
              </Alert>
            )}

            <TextField
              autoFocus
              margin="normal"
              fullWidth
              id="username"
              label="Username"
              name="username"
              {...register("username")}
            />

            <LoadingButton
              type="submit"
              variant="contained"
              sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
              loading={isSubmitting}
            >
              Save
            </LoadingButton>
            <Button
              variant="text"
              sx={{ mt: 3, mb: 2, float: "right" }}
              onClick={() => navigate(-1)}
            >
              Cancel
            </Button>
          </Box>
        </CardContent>
      </Card>
    </div>
  );
}

export default Username;
