import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useAuth } from "../services/auth";
import { LoadingButton } from "@mui/lab";
import {useUtils} from '../services/utilsProvider';

const schema = Yup.object().shape({
  email: Yup.string().email("Invalid email").required("Required"),
});

function ForgotPass() {
  // const [error, setError] = useState(null);

  const navigate = useNavigate();

  const {notify} = useUtils();

  let auth = useAuth();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm({
    resolver: yupResolver(schema)
  });

  const onSubmit = async (values) => {
    try {
      await auth.forgotPass(values);

      notify('An email with instructions on how to change password has been sent.');
    } catch (error) {
      console.log("error", error);
    }
  };

  return (
    <Container component="main" maxWidth="xs">
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <Typography component="h1" variant="h5" marginBottom={2}>
          Reset Password
        </Typography>
        <Typography
          variant="body2"
          sx={{ textAlign: 'center' }}
        >
          Type your email below. You will receive the intructions on how to reset your stitchain account password.
        </Typography>

        <Card variant="outlined" sx={{ maxWidth: 400, mt: 4 }}>
          <CardHeader subheader="Your Stitchain account email" />

          <CardContent>
            <Box
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              noValidate
              sx={{ mt: 1 }}
            >
              {/* {error && (
                <Alert severity="error" sx={{ mt: 3, mb: 2 }}>
                  {error}
                </Alert>
              )} */}

              <TextField
                autoFocus
                margin="normal"
                fullWidth
                id="email"
                type="email"
                label="email"
                name="email"
                {...register("email")}
                error={!!errors.email}
                helperText={errors.email?.message}
              />

              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ mt: 3, mb: 2, ml: 2, float: "right" }}
                loading={isSubmitting}
              >
                Submit
              </LoadingButton>
              <Button
                variant="text"
                sx={{ mt: 3, mb: 2, float: "right" }}
                onClick={() => navigate(-1)}
              >
                Cancel
              </Button>
            </Box>
          </CardContent>
        </Card>
      </Box>
    </Container>
  );
}

export default ForgotPass;
